<template>
  <div>
    <b-row
      class="mb-2"
      align-v="center"
    >
      <b-col>
        <b-button-group>
          <b-button
            v-for="item in menu"
            :key="item.name"
            :variant="currentMenu === item.name ? 'primary': 'outline-primary' "
            @click="currentMenu = item.name"
          >
            {{ item.label }}
            <b-badge
              v-if="item.name === 'pending'"
              variant="danger"
            >
              {{ withdrawsTotal }}
            </b-badge>
            <b-badge
              v-if="item.name === 'hide'"
              variant="danger"
            >
              {{ withdrawHideTotal }}
            </b-badge>
            <b-badge
              v-if="item.name === 'done'"
              variant="danger"
            >
              {{ withdrawDoneTotal }}
            </b-badge>
          </b-button>
        </b-button-group>
      </b-col>
      <b-col
        cols="3"
        class="text-right"
      >
        <label class="d-inline-flex align-items-center">
          <b-form-checkbox
            v-model="toggleStatus"
            switch
          >
            ดึงข้อมูลอัตโนมัติ (ทุกๆ
            {{ intervalTime / 1000 }} วินาที)
          </b-form-checkbox>
        </label>
      </b-col>
    </b-row>
    
    <div>
      <component
        :is="selectedComponent"
        :type="'withdraw'"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  page: {
    title: 'Deposit transactions'
  },
  components: {
    PendingTransactions: () => import('./components/TransactionPending.vue'),
    DoneTransactions: () => import('./components/TransactionDone.vue'),
    HideTransactions: () => import('./components/TransactionHide.vue'),
  },
  data() {
    return {
      currentPage: 1,
      selectedLimit: 20,
      toggleStatus: true,
      currentMenu: 'pending',
      menu: [ {name: 'pending', label: 'รอดำเนินการ'}, {name:'hide', label: 'รายการที่ซ่อนไว้'}, {name:'done', label: 'ดำเนินการแล้ว'} ],
      intervalIdentifier: null,
      intervalTime: 10000,
    }
  },
  computed: {
    ...mapGetters(['withdraws', 'withdrawHide', 'withdrawDone']),
    ...mapState({
      intervalFetch: (state) => state.credit.intervalFetch,
    }),
    withdrawsTotal() {
      return this.withdraws.transactions?.meta.totalItems
    },
    withdrawHideTotal() {
      return this.withdrawHide.transactions?.meta.totalItems
    },
    withdrawDoneTotal() {
      return this.withdrawDone.meta.totalItems
    },
    selectedComponent() {
      switch (this.currentMenu) {
        case 'pending':
          return 'PendingTransactions'
        case 'done':
          return 'DoneTransactions'
        case 'hide':
          return 'HideTransactions'
        default:
          return ''
      }
    },
  },
  watch: {
    toggleStatus(val) {
      if (val) {
        this.startInterval()
      } else {
        this.stopInterval()
      }
    },
  },
  created() {
    this.fetchData()
    this.startInterval();
  },
  beforeDestroy() {
    this.stopInterval()
  },
  methods: {
    ...mapActions([
      'fetchWithdraws',
      'fetchWithdrawDone',
      'fetchWithdrawHide',
    ]),
    startInterval() {
      this.intervalIdentifier = setInterval(() => {
        this.fetchWithdraws({
          limit: this.selectedLimit,
          page: this.currentPage,
          search: '',
          agentId: '',
          masterId: '',
        })
      }, this.intervalTime)
    },
    stopInterval() {
      clearInterval(this.intervalIdentifier)
    },
    fetchData() {
      this.fetchWithdraws({
        limit: this.selectedLimit,
        page: this.currentPage,
        search: '',
        agentId: '',
        masterId: '',
      })
      this.fetchWithdrawDone({
        limit: this.selectedLimit,
        page: this.currentPage,
        search: '',
        agentId: '',
        masterId: '',
      })
      this.fetchWithdrawHide({
        limit: this.selectedLimit,
        page: this.currentPage,
        search: '',
        agentId: '',
        masterId: '',
      })
    },
  },
}
</script>
